import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h1 {...{
      "id": "introduction",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#introduction",
        "aria-label": "introduction permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Introduction`}</h1>
    <p><strong parentName="p">{`Skyframe`}</strong>{` is a full-stack framework for creating sophisticated web & mobile applications at speed. Code like the big companies do, using `}<strong parentName="p">{`monolithic repositories`}</strong>{` driven by a good `}<strong parentName="p">{`domain design`}</strong>{` and boost your productivity with out of the box features like `}<strong parentName="p">{`Authentication`}</strong>{`, `}<strong parentName="p">{`Roles and Permissions`}</strong>{`, `}<strong parentName="p">{`Mailers`}</strong>{` and more!`}</p>
    <p>{`Skyframe provides a level of abstraction beyond common Javascript frameworks like `}<strong parentName="p">{`Angular`}</strong>{`, `}<strong parentName="p">{`Nestjs`}</strong>{`, `}<strong parentName="p">{`Sequelize`}</strong>{` and many more, but also exposes their APIs directly to the developer. This allows developers the freedom to use the myriad of third-party modules which are available for the underlying platform.`}</p>
    <p>{`With these docs you will learn everything about the Skyframe framework, from designing and writing `}<strong parentName="p">{`your first domain`}</strong>{` to building `}<strong parentName="p">{`complex apps for enterprises`}</strong>{`.`}</p>
    <h2 {...{
      "id": "assumptions",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#assumptions",
        "aria-label": "assumptions permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a><strong parentName="h2">{`Assumptions`}</strong></h2>
    <p>{`These docs assume that you are already familiar with `}<a parentName="p" {...{
        "href": "https://developer.mozilla.org/docs/Learn/HTML/Introduction_to_HTML"
      }}>{`HTML`}</a>{`, `}<a parentName="p" {...{
        "href": "https://developer.mozilla.org/docs/Learn/CSS/First_steps"
      }}>{`CSS`}</a>{`, `}<a parentName="p" {...{
        "href": "https://developer.mozilla.org/en-US/docs/Web/JavaScript/A_re-introduction_to_JavaScript"
      }}>{`JavaScript`}</a>{`, and some of the tools from the `}<a parentName="p" {...{
        "href": "https://developer.mozilla.org/en-US/docs/Web/JavaScript/Language_Resources"
      }}>{`latest standards`}</a>{`, such as `}<a parentName="p" {...{
        "href": "https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Classes"
      }}>{`classes`}</a>{` and `}<a parentName="p" {...{
        "href": "https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Statements/import"
      }}>{`modules`}</a>{`. The code samples are written using `}<a parentName="p" {...{
        "href": "https://www.typescriptlang.org/"
      }}>{`TypeScript`}</a>{`.`}</p>
    <h2 {...{
      "id": "feedback",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#feedback",
        "aria-label": "feedback permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a><strong parentName="h2">{`Feedback`}</strong></h2>
    <p>{`We want to hear from you. Report problems or submit suggestions for future docs.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      